<template>
  <div class="page-main">
    <ProductionTabs />

    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" :loading="loading">
      <template #buttons>
        <el-button type="primary" size="mini" @click="itemLingLiao()">领料</el-button>
        <el-button type="primary" size="mini" @click="itemTuiLiao()">退料</el-button>
        <el-button type="primary" size="mini" @click="itemBaoGong()">报工</el-button>
        <el-button type="primary" size="mini" @click="itemPrint">打印任务</el-button>
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      highlight-hover-row
      show-footer
      highlight-current-row
      :loading="loading"
      :columns="tableColumn"
      :data="tableData"
      :max-height="this.$util.getViewHeight() - 187"
      :export-config="{ type: ['xlsx'], types: ['xlsx'] }"
      :keyboard-config="{ isArrow: true }"
      :merge-cells="mergeCell"
      @keydown="tableKeydown"
      @current-change="tableCurrentChange"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_need_wu_liao_code="{ row }">
        <el-link type="primary" @click="openPopupParentCode(row)">
          {{ row.need_wu_liao_code.join(',') }}
        </el-link>
      </template>
      <template #default_ling_liao_count="{ row }">
        <el-link type="primary" @click="itemLingLiao(row)">
          {{ row.ling_liao_count }}
        </el-link>
      </template>
      <template #default_tui_liao_count="{ row }">
        <el-link type="primary" @click="itemTuiLiao(row)">
          {{ row.tui_liao_count }}
        </el-link>
      </template>
      <template #default_bao_gong_count="{ row }">
        <el-link type="primary" @click="itemBaoGong(row)">
          {{ row.bao_gong_count }}
        </el-link>
      </template>
      <template #default_ren_wu_ji_hua_kai_gong_date="{ row }">
        {{ row.ren_wu_ji_hua_kai_gong_date ? row.ren_wu_ji_hua_kai_gong_date.slice(0, 10) : '' }}
      </template>
      <template #default_ren_wu_ji_hua_wan_gong_date="{ row }">
        {{ row.ren_wu_ji_hua_wan_gong_date ? row.ren_wu_ji_hua_wan_gong_date.slice(0, 10) : '' }}
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 领料 -->
    <PopupLingLiao
      v-if="isShowPopupLingLiao"
      :show.sync="isShowPopupLingLiao"
      :data="activeObj"
      @success="searchListData"
    />

    <!-- 退料 -->
    <PopupTuiLiao
      v-if="isShowPopupTuiLiao"
      :show.sync="isShowPopupTuiLiao"
      :data="activeObj"
      @success="searchListData"
    />

    <!-- 报工 -->
    <PopupBaoGong
      v-if="isShowPopupBaoGong"
      :show.sync="isShowPopupBaoGong"
      :data="activeObj"
      @success="searchListData"
    />

    <!-- 所需物料 -->
    <b-popup-parent-code
      v-if="isShowPopupParentCode"
      :show.sync="isShowPopupParentCode"
      :data="activeObj"
    />
  </div>
</template>

<script>
import ProductionTabs from '../common/production-tabs'
import PopupLingLiao from './popup-ling-liao'
import PopupTuiLiao from './popup-tui-liao'
import PopupBaoGong from './popup-bao-gong'
export default {
  components: {
    ProductionTabs,
    PopupLingLiao,
    PopupTuiLiao,
    PopupBaoGong
  },
  data() {
    return {
      loading: true,

      // filter
      filterData: {},
      sortData: {
        cai_gou_stock_id: 'desc'
      },

      // table
      headerFilterUrl: '/admin/gcvip/gongDan/renWuListSearchHeard',
      mergeCell: [],
      tableData: [],
      tableColumn: [
        {
          fixed: 'left',
          type: 'radio',
          width: 40
        },
        {
          title: 'ID',
          field: 'ren_wu_id',
          width: 60,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '工单编号',
          field: 'gong_dan_code',
          isMerge: true,
          width: 110,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '所需物料号',
          field: 'need_wu_liao_code',
          isMerge: true,
          slots: { header: 'header_autocomplete', default: 'default_need_wu_liao_code' }
        },
        {
          title: '任务顺序',
          field: 'ren_wu_order_id',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '任务名称',
          field: 'ren_wu_name',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '规格',
          width: 120,
          field: 'ren_wu_specification',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '任务数量',
          field: 'ren_wu_count',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '领料数量',
          field: 'ling_liao_count',
          slots: { header: 'header_autocomplete', default: 'default_ling_liao_count' }
        },
        {
          title: '退料数量',
          field: 'tui_liao_count',
          slots: { header: 'header_autocomplete', default: 'default_tui_liao_count' }
        },
        {
          title: '报工数量',
          field: 'bao_gong_count',
          slots: { header: 'header_autocomplete', default: 'default_bao_gong_count' }
        },
        {
          title: '单位',
          field: 'ren_wu_unit',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '任务负责人',
          field: 'ren_wu_fu_ze_ren',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '计划开工日期',
          field: 'ren_wu_ji_hua_kai_gong_date',
          slots: { header: 'header_autocomplete', default: 'default_ren_wu_ji_hua_kai_gong_date' }
        },
        {
          title: '计划完工日期',
          field: 'ren_wu_ji_hua_wan_gong_date',
          slots: { header: 'header_autocomplete', default: 'default_ren_wu_ji_hua_wan_gong_date' }
        },
        {
          title: '备注',
          field: 'ren_wu_bei_zhu',
          slots: { header: 'header_autocomplete' }
        }
      ],
      pageOptions: {
        ...this.$constant.page
      },

      isShowPopupLingLiao: false, // 是否显示领料
      isShowPopupTuiLiao: false, // 是否显示退料
      isShowPopupBaoGong: false, // 是否显示报工
      isShowPopupParentCode: false, // 是否显示所需物料弹窗
      activeObj: ''
    }
  },
  mounted() {
    this.getListData()
    this.$nextTick(() => {
      this.$refs.refTable.connect(this.$refs.refToolbar) // 手动将表格和工具栏进行关联
    })
  },
  methods: {
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.loading = true
      this.$api({
        method: 'post',
        url: '/admin/gcvip/gongDan/renWuList',
        data: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          filterData: this.filterData,
          sortData: this.sortData
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            let data = res.data.page
            this.tableData = data.list
            this.pageOptions.pageSize = data.pageSize
            this.pageOptions.total = data.totalRow
            this.pageOptions.currentPage = data.pageNumber
            this.mergeCell = this.$tool.getMergeCellConfigList(
              this.tableData,
              this.tableColumn,
              'gong_dan_code'
            )
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 获取-当前单选行
    getRadioRecord() {
      return this.$refs.refTable.getRadioRecord()
    },
    // 表格-高亮行更改
    tableCurrentChange(params) {
      this.$refs.refTable.setRadioRow(params.row, true) // 选中radio
    },
    // 表格-键盘空格
    tableKeydown(params) {
      if (params.$event.code === 'Space') {
        let row = this.$refs.refTable.getCurrentRecord()
        let isCurrentRowRadio = this.$refs.refTable.isCheckedByRadioRow(row)
        if (isCurrentRowRadio) {
          this.$refs.refTable.clearRadioRow() // 清空radio
        } else {
          this.$refs.refTable.setRadioRow(row) // 选中radio
        }
      }
    },
    // 项-选择提示
    itemChoosePrompt() {
      this.$tool.tableChoosePrompt()
    },
    // 项-领料
    itemLingLiao(r) {
      let row = r || this.getRadioRecord()
      if (row) {
        this.itemLingLiaoAction(row)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-领料
    itemLingLiaoAction(row) {
      this.activeObj = row
      this.isShowPopupLingLiao = true
    },
    // 项-退料
    itemTuiLiao(r) {
      let row = r || this.getRadioRecord()
      if (row) {
        this.itemTuiLiaoAction(row)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-退料
    itemTuiLiaoAction(row) {
      this.activeObj = row
      this.isShowPopupTuiLiao = true
    },
    // 项-报工
    itemBaoGong(r) {
      let row = r || this.getRadioRecord()
      if (row) {
        this.itemBaoGongAction(row)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-报工
    itemBaoGongAction(row) {
      this.activeObj = row
      this.isShowPopupBaoGong = true
    },
    // 项-打印任务
    itemPrint() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemPrintAction(row)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-打印任务
    itemPrintAction(row) {
      window.open(`/production/task/print?id=${row.ren_wu_id}`, '_blank')
    },
    // 所需物料
    openPopupParentCode(row) {
      this.activeObj = row
      this.isShowPopupParentCode = true
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
